<template>
  <div class="container home">
    <transition name="fade">
      <img v-if="showMainImg" src="/image/main/title-up.png" alt="전학와서 생긴 일" class="upper-title" />
    </transition>
    <transition name="fade">
      <img v-if="showMainImg" src="/image/main/title.png" alt="스낵고 학생 유형 테스트" class="main-title" />
    </transition>
    <transition name="fade">
      <button v-if="showMainImg" class="cta" @click="startTest">
        <img src="/image/main/start.png" alt="시작하기" />
      </button>
    </transition>
    <transition name="fade">
      <div v-if="showMainImg" :class="['late-cover', $route.name]">
        <img class="late-text" src="/image/main/late.svg" alt="지각 양파링 떠든사람 닭다리" />
      </div>
    </transition>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Home",
  data() {
    return {
      showMainImg: true
    };
  },
  mounted() {
    document.body.style.backgroundColor = "#004f32";
  },
  methods: {
    startTest() {
      this.showMainImg = false;
      const increment = firebase.firestore.FieldValue.increment(1);
      const docRef = this.$firestore.collection("user").doc("snackgotest");
      docRef.update({ startCount: increment });
      setTimeout(() => {
        this.$router.push({
          name: "Test"
        });
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
.upper-title {
  margin: 150px auto 14px;
  width: 134px;
}
.main-title {
  width: 268px;
  margin-bottom: 44px;
}
div.late-cover {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  max-width: 360px;
  position: relative;
  z-index: 0;
  padding: 50px 0 30px;
  .late-text {
    position: relative;
    width: 94px;
    left: -26px;
  }
}
</style>
